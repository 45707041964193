import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import {
    Chart,
    PieController,
    ArcElement,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { delegate as tippyDelegate } from "tippy.js";
import * as Config from '@/config/redmine-constants';
import { processXlsxResponse, userIsMemberOfProject } from '@/app/helpers';
import KPIVcr from "./KPIVcr.vue";
import KPIIssue from "./KPIIssue.vue";
import VersionSelect from "@/views/common/VersionSelect.vue";
import IconInfo from "@/icons/info-circle-solid.svg";
import IconDownload from "@/icons/download-solid.svg";


Chart.register(
    PieController,
    ArcElement,
    Legend,
    ChartDataLabels,
);
let chart = null;


const statuses = [
    { id: Config.ISSUE_STATUS_NEW, color: '#5494e3', label: Config.ISSUE_STATUS_LABELS[Config.ISSUE_STATUS_NEW] },
    { id: Config.ISSUE_STATUS_ANALYSIS, color: '#3f81d1', label: Config.ISSUE_STATUS_LABELS[Config.ISSUE_STATUS_ANALYSIS] },
    { id: Config.ISSUE_STATUS_ACTIVE, color: '#316cb4', label: Config.ISSUE_STATUS_LABELS[Config.ISSUE_STATUS_ACTIVE] },
    { id: Config.ISSUE_STATUS_RESOLVED, color: '#4ddb66', label: Config.ISSUE_STATUS_LABELS[Config.ISSUE_STATUS_RESOLVED] },
    { id: Config.ISSUE_STATUS_DELIVERY, color: '#41cc5a', label: Config.ISSUE_STATUS_LABELS[Config.ISSUE_STATUS_DELIVERY] },
    { id: Config.ISSUE_STATUS_CLOSED, color: '#36b04c', label: Config.ISSUE_STATUS_LABELS[Config.ISSUE_STATUS_CLOSED] },
    { id: Config.ISSUE_STATUS_REMOVED, color: '#d90d80', label: Config.ISSUE_STATUS_LABELS[Config.ISSUE_STATUS_REMOVED] },
];
function updateChart(chart, data) {
    data.datasets[0].data.forEach((nb, index) => {
        chart.data.datasets[0].data[index] = nb;
    });
    chart.update();
}
function renderChart(chartElm, issuesByStatus) {
    if (issuesByStatus.length === 1 && issuesByStatus[0] === 'test') return;
    const labels = statuses.map((status) => status.label);
    const datasets = [{
        data: statuses.map(status => (issuesByStatus.find(ibs => ibs.id === status.id)||{}).iss_nb||null),
        backgroundColor: statuses.map((status) => status.color),
    }];
    const data = { labels, datasets };
    if (chart) return updateChart(chart, data);
    chart = new Chart(chartElm, {
        type: 'pie',
        data,
        options: {
            responsive: true,
            plugins: {
                datalabels: {
                    color: '#fff',
                    font: {
                        size: 20,
                    }
                },
                legend: {
                    labels: {
                        //@ts-ignore
                        filter: (legendItem, data) => !!data.datasets[0].data[legendItem.index]
                    },
                    position: 'bottom'
                },
            },
        },
    });
}


const defaultData = () => {
    return {
        project: null,
        versions: null,
        versionsAll: [],
        vcrs: [],
        issuesByStatus: [],
        slaSupport: [],
        slaResponse: [],
        vcrVsSpent: {},
        latestIssues: [],
        showDetail: false,
        showSupport: false,
    };
};


export default {
    name: 'KPI',
    components: {
        Multiselect,
        KPIVcr,
        KPIIssue,
        IconInfo,
        IconDownload,
        VersionSelect,
    },
    data: defaultData,
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: 'Resource/auth/user',
            projectsAll: 'Project/list/projects',
        }),
        projects() {
            if (this.user.admin) return this.projectsAll;
            return this.projectsAll.filter(project => userIsMemberOfProject(this.user, project));
        },
        totalIssuesNb() {
            if (!this.issuesByStatus.length) return 0;
            return this.issuesByStatus.reduce((acc, item) => acc + item.iss_nb, 0);
        },
        totalSpent() {
            return (this.vcrVsSpent.spent_hours || 0) - (this.vcrVsSpent.pecm_hours || 0);
        },
        totalRatio() {
            if (!this.totalSpent) return 0;
            return this.totalSpent / this.vcrVsSpent.vcr_hours;
        },
        versionIds() {
            return this.versions.map(ver => ver.id).join(',');
        }
    },
    methods: {
        selectProject() {
            this.versions = defaultData().versions;
            this.vcrs = defaultData().vcrs;
            this.issuesByStatus = defaultData().issuesByStatus;
            this.slaSupport = defaultData().slaSupport;
            this.slaResponse = defaultData().slaResponse;
            this.latestIssues = defaultData().latestIssues;
            if (!this.project) return this.versionsAll = [];
            const projectId = this.project.id;
            this.$store.dispatch("Issue/list/getVersions", projectId).then(versions => {
                this.versionsAll = versions;
                // this.versionsAll = versions.filter(version => version.status === 'open');
            });
        },
        selectVersion() {
            this.$store.dispatch('KPI/list/getVcrs', this.versionIds).then((vcrs) => {
                this.vcrs = vcrs;
            });
            this.$store.dispatch('KPI/list/getVcrVsSpent', this.versionIds).then((vcrVsSpent) => {
                this.vcrVsSpent = vcrVsSpent;
            });
            this.$store.dispatch('KPI/list/getIssuesByStatus', this.versionIds).then((issuesByStatus) => {
                const colorIds = statuses.map(status => status.id);
                issuesByStatus.sort((a, b) => {
                    const indexA = colorIds.indexOf(a.id);
                    const indexB = colorIds.indexOf(b.id);
                    if (indexA > indexB) return 1;
                    if (indexA < indexB) return -1;
                    return 0;
                });
                this.issuesByStatus = issuesByStatus;
                renderChart(this.$refs.chart, issuesByStatus);
            });
            this.$store.dispatch('KPI/list/getSlaSupport', this.versionIds).then((slaSupport) => {
                this.slaSupport = slaSupport;
            });
            this.$store.dispatch('KPI/list/getSlaResponse', this.versionIds).then((slaResponse) => {
                this.slaResponse = slaResponse;
            });
            this.$store.dispatch('KPI/list/getLatestIssues', this.versionIds).then((latestIssues) => {
                this.latestIssues = latestIssues;
            });
        },
        exportSlaResponseDetail() {
            this.$store.dispatch('KPI/list/getSlaResponseDetail', this.versionIds)
                .then(processXlsxResponse("detail.xlsx"));
            ;
        }
    },
    mounted() {
        tippyDelegate('#kpi', {
            target: '[data-tippy]',
            content(ref) {
                return ref.nextElementSibling.innerHTML;
            }
        });
        this.$store.dispatch('Project/list/getList').then(() => {
            if (this.projects.length === 1) {
                this.project = this.projects[0];
                this.selectProject();
            }
        });
    },
}
