<template>
  <div class="card">
    <div class="card-header d-flex align-items-center">
      <h4 class="mb-0">{{ vcr.opportunity_name }}</h4>
      <div class="ml-auto text-muted">
        {{ updatedAt }}
      </div>
    </div>
    <div class="card-body">
      <div class="text-nowrap">{{ vcr.name }}</div>
      <div class="text-center">
        <span class="text-nowrap"
          ><span class="bign">{{
            Math.round((vcr.passed_hours / 8) * 10) / 10
          }}</span>
          jours</span
        >
        consommés sur
        <span class="bign">{{
          Math.round((vcr.vcr_hours / 8) * 10) / 10
        }}</span>
        de crédit,
        <span :class="['text-nowrap', { text_pink: ratio > 1 }]"
          ><span class="bign">{{
            Math.round((vcr.remaining_hours / 8) * 10) / 10
          }}</span>
          restant</span
        >
      </div>
      <div :class="['progress', { bg_pink: ratio > 1 }]">
        <div
          class="progress-bar"
          :style="{
            width: (ratio > 1 ? 2 - ratio : ratio) * 100 + '%',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/config/constants";

export default {
  name: "KPIVcr",
  props: ["vcr"],
  computed: {
    ratio() {
      return this.vcr.passed_hours / this.vcr.vcr_hours;
    },
    updatedAt() {
      return dayjs(this.vcr.updated_at).format(DATE_FORMAT);
    },
  },
};
</script>
