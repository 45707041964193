<template>
  <li class="list-group-item d-flex align-items-center">
    <clink
    class="text-truncate mr-auto"
      :to="{
        name: 'tree',
        params: { type: 'issue', id: issue.id },
      }"
    >{{ issue.subject }}
    </clink>
    <a v-if="issue.devops_url" :href="issue.devops_url" target="_blank">
      <icon-devops />
    </a>
  </li>
</template>

<script>
import IconDevops from "@/icons/azure-devops.svg";

export default {
  name: "KPIIssue",
  props: ["issue"],
  components: {
    IconDevops,
  },
};
</script>
